@import "@/styles/globals.scss";




















































.event-listing-item {
  display: grid;
  grid-auto-columns: auto 1fr;

  .button.primary {
    height: fit-content;
    width: fit-content;
    margin-left: 0;
  }

  @include large {
    .figure-link {
      grid-column: 1;
      grid-row: 1/20;
    }

    .title-link {
      grid-column: 2;
    }

    .subtitle {
      grid-column: 2;
    }

    .description {
      grid-column: 2;
    }

    .venue {
      grid-column: 2;
    }

    .button.primary {
      grid-column: 2;
    }
  }

  @include smallAndMedium {
    .figure-link {
      grid-column: 1;
    }

    .title-link {
      grid-column: 1;
    }

    .subtitle {
      grid-column: 1;
    }

    .description {
      grid-column: 1;
    }

    .venue {
      grid-column: 1;
    }

    .button.primary {
      grid-column: 1;
    }
  }
}
