@import "@/styles/globals.scss";


















































































































// Hide validation errors
.terms-checkbox {
  .message.invalid {
    display: none;
  }
}
