@import "@/styles/globals.scss";















































































































































































































































































































































































































































































































































































.edit-order {
  padding-bottom: 32px;

  .sessions {
    display: flex;
    flex-wrap: wrap;
    margin: 32px -20px -32px;
    align-items: flex-start;
  }

  .session {
    border: 1px solid var(--medium-gray);
    display: flex;
    flex-direction: column;
    margin: 0 20px 32px;
    width: 100%;

    @include mediumOnly {
      width: calc(50% - 40px);
    }

    @include large {
      width: calc(33% - 40px);
    }
  }

  .image-container {
    overflow: hidden;
    background-color: var(--very-light-gray);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .body {
    padding: 16px;
    flex-grow: 1;

    @include large {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  .event-name {
    margin-top: 0;
    margin-bottom: 8px;
  }

  .details {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;
      margin-bottom: 8px;

      &:nth-child(even) {
        padding-left: 16px;
      }

      &.total-tickets,
      &.price {
        margin-bottom: 0;
      }
    }

    h4 {
      display: block;
      font-size: 13px;
      color: var(--medium-gray);
      margin-bottom: 2px;
      font-weight: normal;
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .edit-actions,
  .uneditable-event {
    padding: 0 4px;

    @include mediumAndLarge {
      padding: 0 12px;
    }
  }

  .reschedule-event,
  .cancel-event {
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: var(--secondary-color);
    font-family: inherit;
    text-align: left;
    padding: 16px 12px;

    &:disabled {
      opacity: 0.6;
    }
  }

  .toggle-btn,
  .uneditable-event,
  .ticket {
    padding: 16px;

    @include mediumAndLarge {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .toggle-btn {
    border: none;
    background-color: transparent;
    width: 100%;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    svg {
      align-self: center;
    }
  }

  .edit-actions,
  .uneditable-event,
  .toggle-btn,
  .ticket {
    border-top: 1px solid var(--medium-gray);
  }

  .edit-actions,
  .uneditable-event {
    font-weight: 600;
  }

  .uneditable-event {
    color: #fb8728;
  }

  .order-cancelled-message > .message-content > a {
    color: var(--medium-gray);
    text-decoration: underline;
  }

  .tickets {
    overflow: hidden;
    width: 100%;
  }

  .ticket {
    background-color: var(--light-gray);
    display: flex;
    align-items: center;
    font-size: 14px;

    .ticket-name {
      flex-grow: 1;
    }

    > .price {
      padding-left: 12px;

      &:not(:last-child) {
        padding-right: 12px;
      }
    }

    button.remove {
      border: none;
      background-color: transparent;
      padding: 0;

      svg {
        margin-top: 2px;
      }

      &:disabled {
        opacity: 0.6;
      }
    }
  }

  .price {
    .free {
      text-transform: uppercase;
    }
  }

  .closed {
    .toggle-btn {
      svg {
        transform: rotate(180deg);
      }
    }

    .tickets {
      max-height: 0;
      border-bottom-width: 0;
      transition: max-height 200ms ease-out;
    }
  }

  .open {
    .toggle-btn {
      svg {
        transform: none;
      }
    }

    .tickets {
      display: block;
      transition: max-height 200ms ease-in;
      max-height: 285px;
      overflow-y: auto;
    }
  }

  div.message {
    margin-top: 32px;
  }

  .no-valid-tickets {
    margin-top: 24px;
  }

  .title {
    margin: 8px 0;

    .order-code {
      margin: 0;
      line-height: 1;
    }

    h2 {
      margin-top: 0;
    }

    button.cancel-order {
      margin-top: 16px;

      @include smallAndMedium {
        display: none;
      }
    }
  }

  @include smallAndMedium {
    .unsaved-changes {
      button {
        &.confirm-changes {
          width: 67%;
        }

        &.cancel-changes {
          width: 33%;
        }
      }
    }
  }

  @include large {
    .sticky-footer.cancel-order {
      display: none;
    }
  }

  @include mediumAndLarge {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin-bottom: 0;
      }

      button.cancel-order {
        margin-top: 0;
      }
    }
  }

  .next-steps-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.sticky-footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  border-top: 1px solid var(--light-gray);

  @include smallAndMedium {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    padding: 16px 8px;

    button {
      width: 100%;
      margin: 0 8px;
    }
  }

  @include large {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid var(--medium-gray);

    .btn {
      margin-left: 8px;
    }
  }
}
