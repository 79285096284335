@import "@/styles/globals.scss";



























































































































































button.selectable {
  @include selectable;
}
